

export const ConnectorStatusConfigs: any = {
  '0': {
    className: 'text-dark-gray',
    textKey: 'connectorStatus_0',
  },
  '1': {
    className: 'text-green',
    textKey: 'connectorStatus_1',
  },
  '2': {
    className: 'text-dark-gray',
    textKey: 'connectorStatus_2',
  },
  '3': {
    className: 'text-red',
    textKey: 'connectorStatus_3',
  },
  '4': {
    className: 'text-red',
    textKey: 'connectorStatus_4',
  },
  '99': {
    className: 'text-red',
    textKey: 'connectorStatus_99',
  },
  '5': {
    className: 'text-red',
    textKey: 'connectorStatus_5',
  },
  '6': {
    className: 'text-red',
    textKey: 'connectorStatus_6',
  },
  '7': {
    className: 'text-red',
    textKey: 'connectorStatus_7',
  },
  default: {
    className: 'text-gray',
    textKey: 'connectorStatus_0',
  },
};

export const ActivatingStatusConfigs: any = {
  activating: false,
  plugIn: false,
  isChargingDone: false,
  activationStatus: false,
};

export const ConnectorIdentifierConfigs: any = {
  evseId: '',
  connectorId: '',
  locationId: '',
};
